import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import AppLayout from "../../components/shared/AppLayout";
import successIcon from "../../assets/images/success-icon.svg";

const PageContractPreparationSuccess = () => {
    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} isBackBtn={false} hasFooter={false}>

            <div className="app-page page-contract-preparation-success">
            
                <div className="contract-preparation-success">
            
                    <div className="container">
                        <div className="contract-preparation-success__header">
                            <h2 className="contract-preparation-success__status">
                                <img
                                    className="contract-preparation-success__status-icon img-fluid"
                                    src={successIcon}
                                    alt="icon"
                                    width="44"
                                />
                                <span className="contract-preparation-success__status-text text-bold">
                                    <FormattedMessage id="contract.success.title" />
                                </span>
                            </h2>
                        </div>
                    </div>

                </div>

            </div>

        </AppLayout>
    );
};

export default PageContractPreparationSuccess;
